import Turnstile from "react-turnstile";

export async function checkIfHuman({
	values,
}: {
	values: Record<string, any>;
}) {
	const endpoint = "/api/turnstile";

	const result = await fetch(endpoint, {
		body: JSON.stringify({
			token: values.token,
		}),
		method: "post",
	});

	const outcome = await result.json();

	if (outcome.success) {
		return true;
	}

	return false;
}

export default function TurnstileValidation({
	setFieldValue,
	setError,
}: {
	setFieldValue: (
		field: string,
		value: string
	) => void;
	setError?: (error: string) => void;
}) {
	return (
		<Turnstile
			sitekey={
				process.env
					.NEXT_PUBLIC_TURNSTILE_SITE_KEY ??
				""
			}
			onVerify={(token) => {
				setFieldValue("token", token);
				setError && setError("");
			}}
			onAfterInteractive={() => {
				setError && setError("");
			}}
			onBeforeInteractive={() => {
				setError && setError("");
			}}
			theme='light'
			refreshExpired='auto'
			appearance='interaction-only'
		/>
	);
}
