import {
	Box,
	BoxProps,
} from "@mui/material";
import LinkText from "components/LinkText";
import {
	H2,
	H3,
	H4,
	Paragraph,
	Small,
	Tiny,
} from "components/Typography";
import { FC } from "react";
import {
	LEGAL_CGU_PAGE_URL,
	LEGAL_PRIVACY_POLICY_PAGE_URL,
	FAQ_RETURNS_AND_FUNDS_PAGE_URL,
} from "utils/config";

type TypographyProps = {
	children: React.ReactNode;
	size:
		| "tiny"
		| "small"
		| "normal"
		| "large"
		| "huge"
		| "giant";
};

const Typography: FC<
	TypographyProps
> = ({ children, size }) => {
	//  use switch case to return the right typography
	switch (size) {
		case "tiny":
			return (
				<Tiny component='div'>
					{children}
				</Tiny>
			);
		case "small":
			return (
				<Small component='div'>
					{children}
				</Small>
			);
		case "normal":
			return (
				<Paragraph component='div'>
					{children}
				</Paragraph>
			);
		case "large":
			return (
				<H4
					component='div'
					fontWeight={"normal"}>
					{children}
				</H4>
			);
		case "huge":
			return (
				<H3
					component='div'
					fontWeight={"normal"}>
					{children}
				</H3>
			);
		case "giant":
			return (
				<H2
					component='div'
					fontWeight={"normal"}>
					{children}
				</H2>
			);
	}
};

export interface PrivacyDisclaimerProps {
	variant?: "auth" | "checkout";
	actionName?: string;
	typographySize?: TypographyProps["size"];
	boxProps?: BoxProps;
}

const PrivacyDisclaimer: FC<
	PrivacyDisclaimerProps
> = ({
	variant = "auth",
	actionName = "continuer",
	typographySize = "small",
	boxProps,
}) => {
	return (
		<Box
			sx={{
				...boxProps?.sx,
			}}
			{...boxProps}>
			<Typography size={typographySize}>
				{`En cliquant sur le bouton `}
				<b>{actionName}</b>
				{", "}
				{
					"ou équivalent, vous confirmez avoir lu, compris et accepté"
				}{" "}
				{variant === "checkout" && (
					<>
						{"nos "}
						<LinkText
							href={LEGAL_CGU_PAGE_URL}
							target={"_blank"}
							rel={
								"noopener noreferrer"
							}>
							{
								"Conditions Générales d'Utilisation"
							}
						</LinkText>
						{", "}
						<LinkText
							href={
								FAQ_RETURNS_AND_FUNDS_PAGE_URL
							}
							target={"_blank"}
							rel={
								"noopener noreferrer"
							}>
							{"Politique de retour"}
						</LinkText>
						{", et "}
					</>
				)}
				notre{" "}
				<LinkText
					href={
						LEGAL_PRIVACY_POLICY_PAGE_URL
					}
					target={"_blank"}
					rel={"noopener noreferrer"}>
					Politique de confidentialité
				</LinkText>
				.
			</Typography>
		</Box>
	);
};

export default PrivacyDisclaimer;
