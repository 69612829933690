import { FC } from "react";
import {
	Card,
	CardProps,
	styled,
} from "@mui/material";

const fbStyle = {
	background: "#3B5998",
	color: "white",
};
const googleStyle = {
	background: "#4285F4",
	color: "white",
};

type AuthWrapperProps = {
	passwordVisibility?: boolean;
};

const AuthWrapper = styled<
	FC<AuthWrapperProps & CardProps>
>(
	({
		children,
		// ,
		// passwordVisibility
		...rest
	}) => (
		<Card {...rest}>{children}</Card>
	)
)<CardProps>(
	({ theme, passwordVisibility }) => ({
		padding: "1rem 1rem",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		".passwordEye": {
			color: passwordVisibility
				? theme.palette.grey[600]
				: theme.palette.grey[400],
		},
		".facebookButton": {
			marginBottom: 10,
			...fbStyle,
			"&:hover": fbStyle,
		},
		".googleButton": {
			...googleStyle,
			"&:hover": googleStyle,
		},
		".agreement": {
			marginTop: 12,
			marginBottom: 24,
		},
	})
);

export default AuthWrapper;
