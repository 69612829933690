import RefreshIcon from "@mui/icons-material/Refresh";
import {
	Button,
	IconButton,
	Link,
} from "@mui/material";
import { Small } from "components/Typography";
import { FlexBetween } from "components/flex-box";
import {
	FC,
	useEffect,
	useState,
} from "react";
import BazaarTextField from "./BazaarTextField";
import useSettings from "hooks/useSettings";
import { useAccount } from "contexts/AccountContext";

export interface BazaarVerificationCodeFieldProps {
	email: string;
	onChange: (e: any) => void;
	onBlur: (e: any) => void;
	errorText: string | null;
	touched: boolean | undefined;
	value: string;
	setError: (error: string) => void;
}

const BazaarVerificationCodeField: FC<
	BazaarVerificationCodeFieldProps
> = ({
	email,
	onChange,
	onBlur,
	errorText,
	touched,
	value,
	setError,
}) => {
	const { sendEmailCode } =
		useAccount();

	const {
		settings: {
			email_verification_sent_codes:
				emailVerificationSentCodes,
		},
	} = useSettings();

	const [
		secondsSinceCodeSent,
		setSecondsSinceCodeSent,
	] = useState<number>(0);

	useEffect(() => {
		const codesInfo =
			emailVerificationSentCodes?.filter(
				(code) => code.email === email
			);
		if (!codesInfo?.length) return;
		const codeInfo =
			codesInfo?.[codesInfo.length - 1];

		if (!codeInfo) return;

		const timeElapsed =
			Date.now() - codeInfo.timestamp;

		const countdownStart = 60; // assuming 60 seconds is the desired interval
		const secondsLeft = Math.max(
			countdownStart -
				Math.floor(timeElapsed / 1000),
			0
		);

		setSecondsSinceCodeSent(
			secondsLeft
		);
	}, [
		emailVerificationSentCodes,
		email,
	]);

	useEffect(() => {
		if (secondsSinceCodeSent <= 0)
			return;

		const intervalId = setInterval(
			() => {
				setSecondsSinceCodeSent(
					(prevSeconds) =>
						prevSeconds - 1
				);
			},
			1000
		);

		return () =>
			clearInterval(intervalId);
	}, [secondsSinceCodeSent]);

	const handleResendCode = async () => {
		try {
			await sendEmailCode({
				email,
				setError,
			});
		} catch (error) {}
	};

	return (
		<BazaarTextField
			mb={2}
			required
			fullWidth
			name='verification_code'
			type='number'
			variant='outlined'
			placeholder='123456'
			onBlur={onBlur}
			value={value}
			onChange={onChange}
			label='Code de vérification'
			error={touched && !!errorText}
			helperText={
				<FlexBetween
					gap={2}
					component='span'>
					<Small component='span'>
						{errorText}
					</Small>
					{secondsSinceCodeSent !==
					0 ? (
						<Small component='span'>
							{`Vous n'avez pas reçu le
							code ? Renvoyer dans ${secondsSinceCodeSent}s`}
						</Small>
					) : (
						<Link
							component={Button}
							onClick={
								handleResendCode
							}>
							Vous n&apos;avez pas reçu
							le code ? Renvoyer
						</Link>
					)}
				</FlexBetween>
			}
			InputProps={{
				endAdornment: (
					<IconButton
						onClick={handleResendCode}
						disabled={
							secondsSinceCodeSent !== 0
						}
						aria-label='Renvoyer le code de vérification'
						title='Renvoyer le code de vérification'>
						<RefreshIcon />
					</IconButton>
				),
			}}
		/>
	);
};

export default BazaarVerificationCodeField;
