import { Link } from "@mui/material";
import { H6 } from "components/Typography";
import { FlexBox } from "components/flex-box";
import { useAccount } from "contexts/AccountContext";
import { FC } from "react";

export interface AuthEmailContainerProps {}

const AuthEmailContainer: FC<
	AuthEmailContainerProps
> = ({}) => {
	const {
		checkedEmail,
		setEmailExists,
	} = useAccount();
	return (
		<FlexBox
			gap={2}
			mb={3}
			alignItems='center'>
			<H6>{checkedEmail}</H6>
			<div>
				<Link
					underline='always'
					sx={{ cursor: "pointer" }}
					onClick={() =>
						setEmailExists(null)
					}>
					Modifier
				</Link>
			</div>
		</FlexBox>
	);
};

export default AuthEmailContainer;
