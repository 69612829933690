import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	List,
	ListItem,
} from "@mui/material";
import customerStoreApi from "__api__/storeAPI/customer";
import BazaarCTA from "components/BazaarCta";
import {
	H4,
	H6,
} from "components/Typography";
import { useRouter } from "next/navigation";
import { useState } from "react";

import { useAccount } from "contexts/AccountContext";
import { useAuthDialogContext } from "contexts/AuthDialogContext";
import { cdpClient } from "utils/analytics";
import handleError from "utils/handleError";
import AuthEmailContainer from "./AuthEmailContainer";

type RenderSuccessProps = {
	email: string;
	setIsRequestResetPassword: (
		value: any
	) => void;
};

const RenderSuccess: React.FC<
	RenderSuccessProps
> = ({
	email,
	setIsRequestResetPassword,
}) => {
	const { close, state } =
		useAuthDialogContext();
	const router = useRouter();

	const handleReturnToHome = () => {
		if (state) {
			close();
		}
		router.push("/");
	};

	const handleReturnToLogin = () => {
		setIsRequestResetPassword(false);
	};

	return (
		<Box>
			<Alert
				severity='success'
				sx={{ mb: 2 }}>
				<H4>
					Un lien pour réinitialiser
					votre mot de passe a été
					envoyé à : {email}
				</H4>
			</Alert>
			<H6>
				Vous n&rsquo;avez pas
				re&ccedil;u de lien ?
			</H6>
			<List>
				{[
					"Vérifiez votre dossier spam",
					"Vérifiez votre orthographe et réessayez",
					"Attendez 15 minutes avant de réessayer",
				].map((item, index) => (
					<ListItem key={index}>
						{item}
					</ListItem>
				))}
			</List>
			<ButtonGroup
				fullWidth
				sx={{ mt: 4 }}>
				<Button
					variant='contained'
					color='primary'
					fullWidth
					onClick={handleReturnToHome}>
					Retour à l&rsquo;accueil
				</Button>
				<Button
					variant='contained'
					color='secondary'
					fullWidth
					onClick={handleReturnToLogin}>
					Retour à la connexion
				</Button>
			</ButtonGroup>
		</Box>
	);
};

interface AuthRequestResetPasswordProps {}

const AuthRequestResetPassword: React.FC<
	AuthRequestResetPasswordProps
> = ({}) => {
	const {
		checkedEmail,
		setIsRequestResetPassword,
	} = useAccount();
	const [isLoading, setIsLoading] =
		useState(false);
	const [isSuccess, setIsSuccess] =
		useState(false);

	const [error, setError] =
		useState("");

	const handleFormSubmit = async (
		email: string
	) => {
		setIsLoading(true);

		cdpClient?.track(
			"User Clicked Request Reset Password",
			{
				eventType: "click",
				category: "Auth",
				label: "Request Reset Password",
				email,
			}
		);

		try {
			await customerStoreApi.generateCustomerPasswordToken(
				{ email }
			);
		} catch (error) {
			handleError({
				error,
				setError,
				errorMessage:
					"Une erreur est survenue lors de la demande de réinitialisation de mot de passe. Veuillez réessayer s'il vous plaît.",
				notifyError: false,
				logError: true,
			});
		} finally {
			setIsLoading(false);
			setIsSuccess(true);
		}
	};

	return isSuccess ? (
		<RenderSuccess
			email={checkedEmail ?? ""}
			setIsRequestResetPassword={
				setIsRequestResetPassword
			}
		/>
	) : (
		<>
			<H6 mb={2}>
				Cliquez sur le bouton ci-dessous
				et nous vous enverrons un lien
				pour réinitialiser votre mot de
				passe.
			</H6>
			<AuthEmailContainer />
			<BazaarCTA
				sx={{ mt: 2, mb: 3 }}
				fullWidth
				loading={isLoading}
				type='submit'
				variant='contained'
				onClick={() =>
					handleFormSubmit(
						checkedEmail ?? ""
					)
				}>
				Obtenir le lien de
				réinitialisation
			</BazaarCTA>
			{error && (
				<Alert
					severity='error'
					sx={{ mt: 2 }}>
					{error}
				</Alert>
			)}
			<BazaarCTA
				variant='outlined'
				color='secondary'
				fullWidth
				onClick={() =>
					setIsRequestResetPassword(
						false
					)
				}
				sx={{ mb: 2 }}>
				Annuler
			</BazaarCTA>
		</>
	);
};

export default AuthRequestResetPassword;
