import { Box } from "@mui/system";
import BazaarCTA from "components/BazaarCta";
import {
	H3,
	H4,
	H5,
} from "components/Typography";
import { useAccount } from "contexts/AccountContext";
// import { useAuthDialogContext } from "contexts/AuthDialogContext";
import { usePrerequisitiesContext } from "contexts/PrerequisitiesContext";
import PrerequisitiesAlert from "pages-sections/prerequisities/PrerequisitiesAlert";
// import { useEffect } from "react";
import AuthEmailContainer from "./AuthEmailContainer";
import AuthRequestResetPassword from "./AuthRequestResetPassword";
import EmailLoginForm from "./EmailLoginForm";
import PasswordLoginForm from "./PasswordLoginForm";
// import PersonnalDetailsForm from "./PersonnalDetailsForm";
import { Divider } from "@mui/material";
import { FlexBox } from "components/flex-box";
import RegisterWithCode from "./RegisterWithCode";
import SocialButtons from "./SocialButtons";
import PrivacyDisclaimer from "components/PrivacyDisclaimer";

const AuthForm = () => {
	const {
		// customer,
		checkedEmail,
		emailExists,
		hasSentEmailCodeToCheckedEmail,
		isRequestResetPassword,
		setIsRequestResetPassword,
	} = useAccount();

	const { isCookiesEnabled } =
		usePrerequisitiesContext();
	// const {
	// 	// close: closeAuthDialog,
	// 	setTitle,
	// 	title,
	// } = useAuthDialogContext();

	// useEffect(() => {
	// 	if (
	// 		title === "Compléter Mon Profil"
	// 	)
	// 		return;
	// 	if (customer?.has_account) {
	// 		setTitle("Compléter Mon Profil");
	// 	}
	// }, [
	// 	customer?.has_account,
	// 	title,
	// 	setTitle,
	// ]);
	//  disable profile complétion on sign up
	// if (customer?.has_account) {
	// 	return (
	// 		<>
	// 			<H3 mb={2.5}>
	// 				{`Bienvenue parmi nous ${customer?.first_name}`}
	// 			</H3>
	// 			<H5 mb={1}>
	// 				{`Complétons votre profil pour que vous puissiez profiter pleinement de votre compte.`}
	// 			</H5>
	// 			<PersonnalDetailsForm
	// 				toggleForm={closeAuthDialog}
	// 				submitCTAText='Compléter mon profil'
	// 			/>
	// 		</>
	// 	);
	// }

	if (!isCookiesEnabled) {
		return (
			<PrerequisitiesAlert
				title='Veuillez activer les cookies pour vous connecter ou créer un compte.'
				description="Les cookies semble être désactivés sur votre navigateur. Pour continuer, veuillez les activer. Nous utilisons les cookies pour permettre la sécurisation de votre compte et d'autres fonctionnalités indispensables au bon fonctionnement du site."
				reloadButtonText="Ok, j'ai activé les cookies"
			/>
		);
	}

	if (
		(!hasSentEmailCodeToCheckedEmail &&
			emailExists === false) ||
		emailExists === null ||
		!checkedEmail
	) {
		return (
			<FlexBox
				flexDirection='column'
				rowGap={4}>
				<SocialButtons
					isGoogleActive={true}
					isFacebookActive={false}
				/>
				<Box my={2}>
					<Box
						width='200px'
						mx='auto'>
						<Divider />
					</Box>

					<FlexBox
						justifyContent='center'
						mt={-1.625}>
						<Box
							bgcolor='background.paper'
							px={2}>
							OU
						</Box>
					</FlexBox>
				</Box>

				<H4
					mb={2.5}
					textAlign='center'
					fontWeight='normal'>
					Saisissez votre adresse e-mail
					pour nous rejoindre ou vous
					connecter.
				</H4>
				<EmailLoginForm />
				<PrivacyDisclaimer
					actionName={"Continuer"}
				/>
			</FlexBox>
		);
	}

	return (
		<>
			{emailExists === true ? (
				<>
					<H3 mb={2.5}>
						{isRequestResetPassword
							? "Réinitialiser votre mot de passe"
							: "Quel est votre mot de passe ?"}
					</H3>
					{!isRequestResetPassword && (
						<AuthEmailContainer />
					)}
					{isRequestResetPassword ? (
						<AuthRequestResetPassword />
					) : (
						<>
							<PasswordLoginForm />
							<BazaarCTA
								variant='text'
								color='secondary'
								fullWidth
								onClick={() =>
									setIsRequestResetPassword(
										true
									)
								}
								sx={{
									mt: 4,
								}}>
								Mot de passe oublié ?
							</BazaarCTA>
						</>
					)}
				</>
			) : (
				emailExists === false && (
					<>
						<H3 mb={2.5}>
							Créons votre compte.
						</H3>
						<Box>
							<H5 mb={1}>
								Nous avons envoyé un
								code de vérification à
								votre adresse e-mail :
							</H5>
							<AuthEmailContainer />
						</Box>
						<RegisterWithCode />
					</>
				)
			)}
		</>
	);
};

export default AuthForm;
