"use client";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import BazaarCTA from "components/BazaarCta";
import { useCurrentUrl } from "hooks/useCurrentUrl";
import { FC, Fragment } from "react";
import { cdpClient } from "utils/analytics";
import { BACKEND_URL } from "utils/config";

// =======================================
type SocialButtonsProps = {
	isGoogleActive: boolean;
	isFacebookActive: boolean;
};
// =======================================

const SocialButtons: FC<
	SocialButtonsProps
> = ({
	isGoogleActive,
	isFacebookActive,
}) => {
	const currentUrl = useCurrentUrl();
	if (
		!isGoogleActive &&
		!isFacebookActive
	)
		return null;
	return (
		<Fragment>
			{isGoogleActive && (
				<BazaarCTA
					className='googleButton'
					variant='outlined'
					startIcon={<GoogleIcon />}
					href={`${BACKEND_URL}/store/auth/google?redirectTo=${currentUrl}`}
					onClick={() => {
						cdpClient?.track(
							"Google Sign In Clicked",
							{
								eventType: "click",
								category: "Auth",
								label:
									"Google Sign In Clicked",
							}
						);
					}}>
					Continuer avec Google
				</BazaarCTA>
			)}
			{isFacebookActive && (
				<BazaarCTA
					href={`${BACKEND_URL}/store/auth/facebook?redirectTo=${currentUrl}`}
					className='facebookButton'
					startIcon={<FacebookIcon />}
					onClick={() => {
						cdpClient?.track(
							"Facebook Sign In Clicked",
							{
								eventType: "click",
								category: "Auth",
								label:
									"Facebook Sign In Clicked",
							}
						);
					}}>
					Continuer avec Facebook
				</BazaarCTA>
			)}
		</Fragment>
	);
};

export default SocialButtons;
