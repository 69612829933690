import * as yup from "yup";

export const validateForm = {
	// Nom
	name: yup
		.string()
		.min(2, "Un peu court, non ?")
		.max(50, "Trop de caractères !")
		.required("Le nom est requis")
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),
	first_name: yup
		.string()
		.min(2, "Un peu court, non ?")
		.max(50, "Trop de caractères !")
		.required("Le prénom est requis")
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),
	last_name: yup
		.string()
		.min(2, "Un peu court, non ?")
		.max(50, "Trop de caractères !")
		.required("Le nom  est requis")
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Email
	email: yup
		.string()
		.email(
			"Hmm, ça ne ressemble pas à un email."
		)
		.matches(
			/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
			"L'email doit être valide, comme exemple@domaine.com."
		)
		.required(
			"L'email est indispensable !"
		),

	// Confirmer Email
	confirm_email: yup
		.string()
		.email(
			"Hmm, ça ne ressemble pas à un email."
		)
		.matches(
			/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
			"L'email doit être valide, comme exemple@domaine.com."
		)
		.required(
			"On doit confirmer cet email !"
		)
		.test(
			"emails-match",
			"Les emails doivent être identiques",
			function (value) {
				return (
					this.parent.email === value
				);
			}
		),

	// Mot de passe
	password: yup
		.string()
		.required(
			"Le mot de passe est indispensable. Si vous l'avez oublié, cliquez sur 'Mot de passe oublié' pour le réinitialiser."
		),

	// Nouveau mot de passe
	new_password: yup
		.string()
		.required(
			"Le nouveau mot de passe est indispensable."
		)
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/,
			"Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial."
		),

	// Confirmer le mot de passe
	confirm_password: yup
		.string()
		.required(
			"La confirmation du mot de passe est indispensable."
		)
		.test(
			"passwords-match",
			"Les mots de passe doivent être identiques",
			function (value) {
				return (
					this.parent.new_password ===
					value
				);
			}
		),

	// Adresse (obligatoire)
	address_required: yup
		.string()
		.required("L'adresse est requise")
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Adresse
	address: yup
		.string()
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Ville
	city: yup
		.string()
		.required("La ville est requise")
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// État
	state: yup
		.string()
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Code postal
	postal_code_required: yup
		.string()
		.required(
			"Le code postal est requis"
		)
		.matches(
			/^[0-9]{5}$/,
			"Le code postal doit être composé de 5 chiffres."
		)
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),
	postal_code: yup
		.string()
		.matches(
			/^[0-9]{5}$/,
			"Le code postal doit être composé de 5 chiffres."
		)
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Pays
	country: yup
		.string()
		.required("Le pays est requis")
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Téléphone
	phone: yup
		.string()
		.matches(
			/^(?:\+33\s*[1-9](?:\s*[0-9]{2}){4}|0033\s*[1-9](?:\s*[0-9]{2}){4}|0[1-9](?:\s*[0-9]{2}){4})$/,
			"Le numéro de téléphone doit être un numéro français valide."
		),

	phone_required: yup
		.string()
		.required(
			"Le numéro de téléphone est requis"
		)
		.matches(
			/^(?:\+33\s*[1-9](?:\s*[0-9]{2}){4}|0033\s*[1-9](?:\s*[0-9]{2}){4}|0[1-9](?:\s*[0-9]{2}){4})$/,
			"Le numéro de téléphone doit être un numéro français valide."
		),

	// Termes et conditions
	terms_and_conditions: yup
		.boolean()
		.oneOf(
			[true],
			"Faut accepter pour continuer !"
		),

	// Code de vérification
	verificationCode: yup
		.string()
		.required("Obligatoire.")
		.matches(
			/^[0-9]{6}$/,
			"Le code de verification doit contenir 6 chiffres."
		),

	// Préférence de mode
	fashion_preference: yup
		.string()
		.min(
			1,
			"Choisissez ce que vous aimez !"
		)
		.required(
			"Dites-nous ce que vous aimez !"
		),

	// Jour
	day: yup
		.string()
		.required(
			"Le date de naissance est requise"
		)
		.test(
			"day",
			"Le jour semble incorrect",
			(value) => {
				const day = Number(value);
				return day >= 1 && day <= 31;
			}
		),

	// Mois
	month: yup
		.string()
		.required("Le mois est requis")
		.test(
			"month",
			"Le mois semble incorrect",
			(value) => {
				const month = Number(value);
				return (
					month >= 1 && month <= 12
				);
			}
		),

	// Année
	year: yup
		.string()
		.required("L'année est requise")
		.test(
			"year",
			"Vopus devriez avoir au moins 13 ans",
			(value) => {
				const year = Number(value);
				const currentYear =
					new Date().getFullYear();
				return (
					// year >= currentYear - 120 &&
					year <= currentYear - 13
				);
			}
		),

	// Code du pays
	country_code: yup
		.string()
		.required(
			"Le code du pays est requis"
		),

	// Nom de l'entreprise
	company_name: yup
		.string()
		.matches(
			/^[^<>]+$/,
			"Pas de balises HTML ou JavaScript, s'il vous plaît."
		),

	// Métadonnées
	metadata: yup.object(),
};
