import { Alert } from "@mui/material";
import BazaarCTA from "components/BazaarCta";
import BazaarEmailField from "components/BazaarEmailField";
import TurnstileValidation, {
	checkIfHuman,
} from "components/TrunstileValidation";
import { useAccount } from "contexts/AccountContext";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useTurnstile } from "react-turnstile";
import { cdpClient } from "utils/analytics";
import { validateForm } from "utils/formValidations";
import * as yup from "yup";

interface EmailLoginFormProps {}

interface FormValues {
	email: string;
	token: string;
}

const EmailLoginForm: FC<
	EmailLoginFormProps
> = ({}) => {
	const turnstile = useTurnstile();
	const [isLoading, setIsLoading] =
		useState(false);
	const [error, setError] = useState<
		null | string
	>(null);

	const {
		sendEmailCode,
		checkIfEmailExists,
		checkedEmail,
	} = useAccount();

	const handleFormSubmit = async (
		values: FormValues
	) => {
		setIsLoading(true);
		setError(null);

		cdpClient?.track(
			"Email Login Form Submitted",
			{
				eventType: "click",
				category: "Auth",
				label: "Email Login Form",
				email: values.email,
			}
		);

		const isValid = await checkIfHuman({
			values,
		});

		if (!isValid) {
			setError(
				"Nous arrivons pas à vérifer que vous n'êtes pas un robot. Veuillez réessayer."
			);
			turnstile?.reset();
			setIsLoading(false);
			return;
		}

		const exists =
			await checkIfEmailExists({
				email: values.email,
				setError,
			});

		if (!exists) {
			await sendEmailCode({
				email: values.email,
				setError,
			});
		}

		turnstile?.reset();
		setIsLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			email: checkedEmail ?? "",
			token: "",
		},
		onSubmit: handleFormSubmit,
		validationSchema: yup
			.object()
			.shape({
				email: validateForm.email,
				token: yup.string(),
			}),
	});

	return (
		<form
			onSubmit={formik.handleSubmit}>
			<BazaarEmailField
				fullWidth
				required
				{...formik.getFieldProps(
					"email"
				)}
				//  delete space in email input value
				onChange={(e) => {
					e.target.value =
						e.target.value.trim();
					formik.handleChange(e);
				}}
				label='Email'
				error={
					Boolean(
						formik.touched.email &&
							formik.errors.email
					) || Boolean(error)
				}
				helperText={
					formik.touched.email &&
					formik.errors.email
				}
			/>
			<TurnstileValidation
				setFieldValue={
					formik.setFieldValue
				}
				setError={setError}
			/>

			{error && (
				<Alert
					severity='error'
					sx={{ mt: 2 }}>
					{error}
				</Alert>
			)}

			<BazaarCTA
				sx={{ mt: 2 }}
				loading={isLoading}
				type='submit'>
				{"Continuer"}
			</BazaarCTA>
		</form>
	);
};

export default EmailLoginForm;
